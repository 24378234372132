import api from ".";

export const getCallerList = (params) => {
    return api.get('/caller', {
        params: params
    })
}
export const getCallerAnalyse = (params = {}) => {
    return api.post('/analyse', {
        cycle: params.cycle || 'DAY',
        country: params.country || [],
        section: params.section || 7,
        endDate: params.endDate,
        syk: params.syk || 1,
    })
}
export default {};
