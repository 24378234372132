<template>
  <router-view/>
</template>
<script setup></script>
<style lang="scss">
 * {
    padding: 0;
    margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
:root {
  --theme-color: #a1d4ae;
}
a {
  color: #2c3e50;
  text-decoration: none;
}
li {
  list-style: none;
  list-style-position: inside;
}
li::marker {
  margin-right: 2px;
}
</style>
