<template>
  <div class="product-list">
    <div class="header-filter">
      <el-input
        placeholder="请输入"
        v-model="keyword"
        style="width: 200px"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px;" @click="handleSearch">查询</el-button>
      <el-select
        v-model="salesNumber"
        placeholder="Select"
        style="width: 180px; margin-left: 10px;"
        @change="changeSales"
      >
        <el-option 
          label="全部"
          :value="-1"
        />
        <el-option 
          label="已上架"
          :value="1"
        />
        <el-option 
          label="未上架"
          :value="0"
        />
      </el-select>
    </div>
    <div class="center-main">
      <el-auto-resizer>
        <template #default="{ height }">
          <el-table
            v-loading="loading"
            :data="productData"
            style="width: 100%;"
            :height="height"
            indent="24"
            ref="tableContent"
            header-row-class-name="product-table-header"
          >
             <el-table-column type="expand">
                <template #default="props">
                    <div>
                        <h3 style="margin-left:20px;line-height: 48px;">Detail</h3>
                        <el-auto-resizer>
                            <template #default="{ width }">
                                <el-table 
                                    :data="props.row.detail" 
                                    border 
                                    :style="{
                                        width: (width - 40) + 'px',
                                        margin: '0 auto'
                                    }"
                                >
                                    <el-table-column label="显示" prop="display" width="240px"></el-table-column>
                                    <el-table-column label="值" prop="value"></el-table-column>
                                </el-table>
                            </template>
                        </el-auto-resizer>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="coverList" 
                label="图片"
                width="144"
            >
                <template #default="scope">
                    <div v-if="!scope.row.coverList || scope.row.coverList.length == 0" class="no-images">没有添加图片</div>
                    <el-carousel 
                        v-else
                        trigger="click" 
                        class="table-image-column"
                        indicator-position="none"
                        arrow="never"
                    >
                        <el-carousel-item v-for="(item) in scope.row.coverList" :key="item">
                            <el-image :src="item" fit="cover">
                                <template #error>
                                    <div class="image-slot">
                                        <el-icon><icon-picture /></el-icon>
                                    </div>
                                </template>
                            </el-image>
                        </el-carousel-item>
                    </el-carousel>
                </template>
            </el-table-column>
            <el-table-column
              prop="brand"
              label="品牌"
              show-overflow-tooltip
            />
            <el-table-column
              prop="type1"
              label="一级分类"
              show-overflow-tooltip
            />
            <el-table-column
              prop="type2"
              label="二级分类"
              show-overflow-tooltip
            />
            <el-table-column
              prop="theme"
              label="主题"
              show-overflow-tooltip
            />
            <el-table-column label="操作" width="260">
                <template #default="scope">
                  <el-button type="primary" @click="handleChange(scope.row)" :disabled="scope.row.salesNumber">编辑</el-button>
                  <el-button
                    :type="scope.row.salesNumber?'info':'success'"
                    @click="handleOperate(scope.row)"
                  >
                    {{ scope.row.salesNumber ? '下架' : '上架' }}
                  </el-button>
                  <el-popconfirm
                    hide-icon 
                    cancel-button-type="info"
                    confirm-button-type="success"
                    @confirm="handleDetele(scope.row.id)"
                  >
                    <template #reference>
                      <el-button type="danger" :disabled="scope.row.salesNumber">删除</el-button>
                    </template>
                  </el-popconfirm>
                </template>
            </el-table-column>
          </el-table>
        </template>
      </el-auto-resizer>
      <div class="footer-pager">
        <el-pagination
          v-model="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 30, 50, 100]"
          layout="sizes, prev, pager, next, jumper, ->, total"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
  <el-dialog
    v-model="dialogVisible"
    title="Tips"
    width="1200"
    :before-close="handleClose"
  >
    <div class="product-edit">
      <EditProduct :data="selectRow"></EditProduct>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="error" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleSlected">
          修改
        </el-button>
        <el-button type="success" @click="handleSuccess">
          修改并上架
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  ElTable,
  ElTableColumn,
  ElInput,
  ElPagination,
  ElAutoResizer,
  ElCarousel,
  ElCarouselItem,
  ElImage,
  ElIcon,
  IconPicture,
  ElButton,
  ElMessage,
  ElDialog,
  ElPopconfirm,
  ElSelect,
  ElOption
} from "element-plus";
import EditProduct from './EditProduct.vue';
import { getProductList, deteleProduct, pactProduct } from '../../api/produt';

const currentPage = ref(1);
const pageSize = ref(10);
const keyword = ref('');
const total = ref(50);
const productData = ref([]);
const loading = ref(true);
const dialogVisible = ref(false);
const selectRow = ref({});
const tableContent = ref();
const salesNumber = ref(-1);
const getProducData = () => {
    loading.value = true;
    productData.value=[];
    const params = {
      $limit: pageSize.value,
      $skip: ((currentPage.value - 1) * pageSize.value),
      theme: keyword.value.trim(),
      '$sort[createdAt]': -1
    }
    if (salesNumber.value != -1) params.salesNumber = salesNumber.value;
    getProductList(params).then(res => {
        tableContent.value.doLayout();
        productData.value = res.data;
        total.value = res.total;
        loading.value = false;
    }).catch(err => {
        console.log(err);
    })
};
const handleSizeChange = (value) => {
  pageSize.value = value;
  getProducData();
};
const handleCurrentChange = (value) => {
  currentPage.value = value;
  getProducData();
};
const handleDetele = (id) => {
    deteleProduct(id).then(res => {
        if (res.code === 200) {
            ElMessage.success('删除成功');
            getProducData();
        } else {
            ElMessage.success('删除失败');
        }
    }).catch(() => {
        ElMessage.success('删除失败, 服务器错误');
    });
}
const handleChange = ({coverList, detail, ...other}) => {
  dialogVisible.value = true;
  selectRow.value = {
    ...other,
    coverList: [...(coverList?.map(item => ({url: item})) || [])],
    detail: detail?.map(item => ({display: item.display, value: item.value, required: false})) || []
  };
};
const changeSales = () => {
  getProducData();
}
const handleSuccess = () => {
    if (
      selectRow.value.detail.every((item) => (item.value?.trim() && item.display?.trim())) &&
      selectRow.value.brand &&
      selectRow.value.theme &&
      selectRow.value.type2 &&
      selectRow.value.coverList.length
    ) {
      const { id, detail, coverList, ...other } = selectRow.value;
      pactProduct(id, {
        ...other,
        salesNumber: 1,
        detail: detail.map(item => ({display: item.display, value: item.value})),
        coverList: coverList.map(item => (item.response?.url || item.url))
      }).then(res => {
        if (res.code == 200) {
          ElMessage.success('修改成功');
          getProducData();
          dialogVisible.value = false;
        } else {
          ElMessage.warning('修改失败');
        }
      })
    } else {
      ElMessage.warning('请填写完信息');
    }
}
const handleSlected = () => {
  const { id, detail, coverList, ...other } = selectRow.value;
  pactProduct(id, {
    ...other,
    salesNumber: 0,
    detail: detail.map(item => ({display: item.display, value: item.value})),
    coverList: coverList.map(item => (item.response?.url || item.url))
  }).then(res => {
    if (res.code == 200) {
      ElMessage.success('修改成功');
      getProducData();
      dialogVisible.value = false;
    } else {
      ElMessage.warning('修改失败');
    }
  })
}
const handleOperate = (row) => {
  if (!row.salesNumber) {
    if (      
      row.detail.every((item) => (item.value?.trim() && item.display?.trim())) &&
      row.brand &&
      row.theme &&
      row.type2 &&
      row.coverList.length
    ) {
      pactProduct(row.id, {salesNumber: 1}).then(res => {
        if (res.code == 200) {
          ElMessage.success('上架成功')
          getProducData();
        } else {
          ElMessage.error('上架失败' + res.msg);
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      ElMessage.warning('商品信息不全，请前往填写相关信息')
    }
  } else {
    pactProduct(row.id, {salesNumber: 0}).then(res => {
      if (res.code == 200) {
        ElMessage.success('下架成功')
        getProducData();
      } else {
        ElMessage.error('下架失败' + res.msg);
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
const handleSearch = () => {
  currentPage.value = 1;
  getProducData();
}
onMounted(() => {
  getProducData();
  document.onresize = () => {
    console.log(this);
  };
});
</script>
<style scoped>
.product-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header-filter,
.footer-pager {
  height: 60px;
  display: flex;
  align-items: center;
}
.footer-pager {
  justify-content: center;
}
.center-main {
  flex: 1;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(222, 222, 222, 0.8);
  display: flex;
  flex-direction: column;
}
.table-image-column,
.no-images {
    width: 120px;
    height: 120px;
    box-shadow: 0 0 10px rgba(222, 222, 222, 0.8);
}
.no-images {
  line-height: 120px;
  text-align: center;
}
.image-slot {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.product-edit { 
  height: 450px;
  overflow: auto;
}
.product-edit::-webkit-scrollbar {
  display: none;
}
.el-table {
  flex: 1;
}
.el-pagination {
  height: 60px;
}
:deep(.product-table-header) {
  color: #000;
  font-weight: 600;
  border-bottom: 2px solid var(--theme-color);
}
:deep(.el-popconfirm__main) {
  color: red;
}
</style>
