<template>
    <div class="main">
        <p>404</p>
        <p>Not Found Page</p>
    </div>
</template>

<script setup></script>

<style scoped>
.main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
}
</style>