import api from '.';

export const getProductList = (params) => {
    return api.get('/products', {
        params: params
    })
}
export const postProduct = (data) => {
    return api.post('/products', data);
}
export const pactProduct = (id, data) => {
    return api.patch('/products/' + id, data);
}
export const deteleProduct = (id) => {
    return api.delete(`/products/${id}`);
}

export default {};
