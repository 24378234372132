<template>
    <div class="clients-list">
        <div class="header-filter">
            <div class="header-item">
                <label for="cycle">统计周期</label>
                <el-select id="cycle" v-model="params.cycle" @change="handleCyceEvent" style="width: 180px;margin-left: 10px;">
                    <el-option label="天" value="DAY" />
                    <el-option label="周" value="WEEK" />
                    <el-option label="月" value="MONTH" />
                    <el-option label="年" value="YEAR" />
                </el-select>
                 <el-input-number v-model="params.section" :min="1" :max="50" @change="handleSectionEvent" />
            </div>
            <div class="header-item">
                <el-date-picker
                    v-model="params.endDate"
                    value-format="YYYY-MM-DD"
                    type="date"
                    placeholder="选择日期"
                    style="width: 180px;"
                    @change="handleEndDateEvent"
                />
            </div>
            <div class="header-item">
                <label for="country">国家</label>
                <el-select 
                    id="country"
                    v-model="params.country" 
                    @change="handleCountryEvent" 
                    style="width: 180px;margin-left:10px"
                    multiple
                >
                    <template v-for="item of countryData" :key="item.id">
                        <el-option :label="item.lable" :value="item.value" />
                    </template>
                    <template #footer>
                        <div class="add-country">
                            <el-input type="text" v-model="addCountry">
                                 <template #append>
                                    <span class="add-country-btn" @click="handleAddCountryEvent">
                                        <el-icon style="font-size: 24px;color: var(--theme-color);">
                                            <Plus />
                                        </el-icon>
                                    </span>
                                 </template>
                            </el-input>
                        </div>
                    </template>
                </el-select>
            </div>
        </div>
        <div class="center-main">
            <el-auto-resizer>
                <template #default="{ height }">
                    <el-table
                        :data="callerAnalyseData"
                        style="width: 100%;"
                        :height="height"
                        header-row-class-name="clients-table-header"
                    >
                        <template 
                            v-for="column of callerHeaderData"
                            :key="column.prop"
                        >   
                            <el-table-column
                                v-if="column.prop == 'section'"
                                :prop="column.prop"
                                :label="column.label"
                                show-overflow-tooltip
                            >
                                <template #default = {row}>
                                    {{ formatDate(row.section, params.cycle) }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                v-else
                                :prop="column.prop"
                                :label="column.label"
                                show-overflow-tooltip
                            />
                        </template>
                    </el-table>
                </template>
            </el-auto-resizer>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { 
    ElAutoResizer, 
    ElTable,
    ElTableColumn, 
    ElSelect, 
    ElOption, 
    ElInputNumber, 
    dayjs, 
    ElInput,
    ElIcon,
    ElDatePicker
} from 'element-plus';
import { Plus } from '@element-plus/icons-vue';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import weekday from 'dayjs/plugin/weekday';
import zhCn from 'dayjs/locale/zh-cn';
import { getCallerAnalyse } from '@/api/caller';

dayjs.extend(weekOfYear);
dayjs.extend(isoWeeksInYear);
dayjs.extend(weekday);
dayjs.locale(zhCn);
const callerHeaderData = ref([
    {
        prop: 'section',
        label: '日期'
    },
    {
        prop: 'count',
        label: '总数'
    }
]);
const callerAnalyseData = ref([]);
const params = ref({
    cycle: 'DAY',
    country: '',
    section: 7,
    syk: 1,
    endDate: dayjs().format('YYYY-MM-DD'),
});
const countryData = ref([
    {
        id: 1,
        lable: '美国',
        value: '美国',
    },
    {
        id: 2,
        lable: '日本',
        value: '日本',
    }
])
const getTableData = async () => {
    try {
        const result = await getCallerAnalyse(params.value);
        callerHeaderData.value = result.data.headerTable;
        callerAnalyseData.value = result.data.result;
    } catch(err) {
        console.log('错误', err);
    }
}
const todayDate = {
    year: dayjs().year(),
    week: dayjs().day(0).week(),
    month: dayjs().month() + 1,
    day: dayjs().date()
};
const handleCyceEvent = (value) => {
    params.value.cycle = value;
    getTableData();
}
const handleSectionEvent = (value) => {
    params.value.section = value;
    getTableData();
}
const handleCountryEvent = (value) => {
    params.value.country = value
    getTableData();
}
const formatDate = (date, type) => {
    const [yaer_, month_week, day_] = date.split('-');
    if (type === 'WEEK' && yaer_ == todayDate.year && month_week== todayDate.week) return '本周';
    if (type === 'MONTH' && yaer_ == todayDate.year && month_week == todayDate.month) return '本月';
    if (type === 'YEAR' && yaer_ == todayDate.year) return '今年';
    if (type === 'DAY' && yaer_ == todayDate.year && month_week == todayDate.month && day_ == todayDate.day) return '今天';
    return `${yaer_}年${month_week?month_week + (type === 'WEEK'?'周':'月'):''}${day_?day_ + '日':''}`;
}
const addCountry = ref('');
const handleAddCountryEvent = () => {
    countryData.value.push({
        id: countryData.value.length,
        lable: addCountry.value,
        value: addCountry.value
    });
}
const handleEndDateEvent = (value) => {
    params.value.endDate = value || dayjs().format('YYYY-MM-DD');
    getTableData();
}
onMounted(() => {
    getTableData();
});
</script>

<style scoped>
.clients-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header-filter {
  height: 60px;
  display: flex;
  align-items: center;
}
.center-main {
  flex: 1;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(222, 222, 222, 0.8);
  display: flex;
  flex-direction: column;
}
.header-item {
    padding: 0 10px;
}
.add-country {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-country-btn{
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;}
.el-table {
  flex: 1;
}
:deep(.clients-table-header) {
  color: #000;
  font-weight: 600;
  border-bottom: 2px solid var(--theme-color);
}
</style>