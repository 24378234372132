<template>
  <el-config-provider :locale="zhCn">
    <div class="layout">
      <el-container>
        <el-aside style="max-width: 200px">
          <h3 class="logo">为德后台管理</h3>
          <el-menu
            class="el-menu-vertical-demo"
            :default-active="page"
            background-color="#2c3033"
            text-color="#ffffff"
            active-text-color="#ffffff"
            unique-opened
            @select="handleSelect"
          >
            <el-sub-menu index="product">
              <template #title>
                <span>商品管理</span>
              </template>
              <el-menu-item
                index="productList"
                @click="moudule = ProductList"
                >商品列表</el-menu-item
              >
              <el-menu-item
                index="addProduct"
                @click="moudule = AddProduct"
                >添加商品</el-menu-item
              >
            </el-sub-menu>
            <el-sub-menu index="clients">
              <template #title>
                <sapn>客户信息管理</sapn>
              </template>
              <el-menu-item
                index="clientList"
                @click="moudule = ClientsList"
                >客户列表</el-menu-item
              >
              <el-menu-item
                index="addClient"
                @click="moudule = AddClient"
                >添加客户</el-menu-item
              >
            </el-sub-menu>
            <el-sub-menu index="caller">
              <template #title>
                <sapn>访客信息</sapn>
              </template>
              <el-menu-item
                index="callerList"
                @click="moudule = CallerList"
                >访客记录</el-menu-item
              >
              <el-menu-item
                index="CallerAnalyse"
                @click="moudule = CallerAnalyse"
                >访客数据分析</el-menu-item
              >
            </el-sub-menu>
          </el-menu>
        </el-aside>
        <el-container>
          <el-header>
            <div>{{ pageName }}</div>
            <el-dropdown
              size="large"
              trigger="click"
            >
              <span class="user-tatus"> {{ store.state.userInfo.name }} </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="isChangePass = true"
                    >修改密码</el-dropdown-item
                  >
                  <el-dropdown-item @click="handleLogout"
                    >退出登录</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-header>
          <el-main>
            <component :is="moudule"></component>
          </el-main>
        </el-container>
      </el-container>
    </div>
    <el-dialog
      v-model="isChangePass"
      title="修改密码"
      width="500"
      :before-close="handleClose"
    >
      <div class="change-password">
        <el-input
          v-model="password"
          placeholder="密码"
          size="lager"
          type="password"
          show-password
        ></el-input>
        <el-input
          v-model="password2"
          placeholder="确认密码"
          type="password"
          show-password
        ></el-input>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="isChangePass = false">取消</el-button>
          <el-button
            type="primary"
            @click="comfirmPassword"
          >
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </el-config-provider>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import {
  ElContainer,
  ElAside,
  ElHeader,
  ElMain,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElDialog,
  ElButton,
  ElInput,
  ElMessage,
  ElMessageBox,
  ElMenu,
  ElSubMenu,
  ElMenuItem,
  ElConfigProvider
} from "element-plus";
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import { useStore } from "vuex";
import { logout, getUserInfo, putUserPasword } from "@/api/users";
import ProductList from "./pages/ProductList.vue";
import AddProduct from "./pages/AddProduct.vue";
import ClientsList from "./pages/ClientsList.vue";
import AddClient from "./pages/AddClient.vue";
import CallerList from "./pages/CallerList.vue";
import CallerAnalyse from "./pages/CallerAnalyse.vue";
const store = useStore();
const isChangePass = ref(false);
const handleLogout = () => {
  ElMessageBox.confirm("确定退出登录？", {
    type: "info",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  })
    .then(() => {
      logout();
    })
    .catch(() => {});
};
const password = ref("");
const password2 = ref("");
const comfirmPassword = () => {
  if (password.value !== password2.value) {
    ElMessage.error("两个密码不一致");
  } else {
    putUserPasword({ password: password.value });
  }
};
const page = ref(sessionStorage.getItem('page') || "productList");
const pageName = computed(() => {
  if (page.value === "productList") return "商品列表";
  if (page.value === "addProduct") return "添加商品";
  if (page.value === "clientList") return "客户列表";
  if (page.value === "addClient") return "添加客户";
  if (page.value === "callerList") return "访客记录";
  if (page.value === "CallerAnalyse") return "访客数据分析";
  return "未知页面";
});
const moudule = ref(null);
const handleSelect = (key) => {
  page.value = key;
};
onMounted(() => {
  getUserInfo();
  if (page.value === "productList") moudule.value = ProductList;
  if (page.value === "addProduct") moudule.value = AddProduct;
  if (page.value === "clientList") moudule.value = ClientsList;
  if (page.value === "addClient") moudule.value = AddClient;
  if (page.value === "callerList") moudule.value = CallerList;
  if (page.value === "CallerAnalyse") moudule.value = CallerAnalyse;
  window.onbeforeunload = function() {
    sessionStorage.setItem('page', page.value);
  }
});
</script>

<style scoped lang="scss">
.layout {
  width: 100%;
  height: 100vh;
  min-height: 400px;
}
.el-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.el-aside {
  background-color: #2c3033;
  &::-webkit-scrollbar {
    display: none;
  }
}
.el-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #a1d4bb;
}
.el-main {
  padding: 0 20px;
}
.logo {
  width: 200px;
  height: 60px;
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  color: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  letter-spacing: 4px;
}
.el-menu {
  margin-top: 60px;
}
.el-menu-item.is-active {
  background-color: #a1d4bb;
}
.el-menu-item:hover {
  background-color: rgb(129, 170, 150);
}
:deep(.el-sub-menu__title:hover) {
  background-color: rgb(129, 170, 150);
}
.user-tatus {
  display: block;
  min-width: 100px;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
  outline: none;
  margin-right: 20px;
}
.change-password {
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .el-input {
    width: 80%;
  }
}
</style>
