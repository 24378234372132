<template>
    <div class="login">
        <el-form ref="loginForm" size="large" :model="loginInfo">
            <h3 class="login-title">VIVID</h3>
            <el-form-item prop="name" required>
                <el-input v-model="loginInfo.name" placeholder="用户名" />
            </el-form-item>
            <el-form-item prop="password" required>
                <el-input v-model="loginInfo.password" placeholder="密码" type="password" show-password />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleLogin">LOGIN</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
    import { ref, reactive } from "vue";
    import { ElForm, ElFormItem, ElInput, ElButton } from "element-plus";
    import { login } from "@/api/users";

    const loginForm = ref(null);
    const loginInfo = reactive({
        name: "",
        password: "",
        strategy: "local",
    })
    const handleLogin = () => {
        loginForm.value.validate((vaild) => {
            if (vaild) {
                login(loginInfo)
            }
        })
    };
</script>

<style scoped>
    .el-button--primary {
        --submit-btn: #a1d4bb;
        --el-button-bg-color: var(--submit-btn) !important;
        --el-button-border-color: var(--submit-btn) !important;
        --el-button-outline-color: var(--submit-btn) !important;
        --el-button-active-color: var(--submit-btn) !important;
        --el-button-hover-bg-color: var(--submit-btn) !important;;
        --el-button-hover-border-color: var(--submit-btn) !important;;
        --el-button-active-bg-color: var(--submit-btn) !important;;
        --el-button-active-border-color: var(--submit-btn) !important;;
        --el-button-disabled-text-color: var(--submit-btn) !important;;
        --el-button-disabled-bg-color: var(--submit-btn) !important;;
        --el-button-disabled-border-color: var(--submit-btn) !important;;
    }
    .login {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .el-form {
        width: 400px;
        padding: 20px;
        margin: 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 0 10px #a1d4bb;
    }

    .el-form-item {
        width: 80%;
        margin-bottom: 30px;
    }
    .el-button {
        font-size: 20px;
        width: 100%;
    }
    .login-title {
        color: #a1d4bb;
        line-height: 120px;
        font-size: 48px;
        font-weight: 600;
        text-align: center;
    }
</style>
